<template>
  <Navbar name="Feature Profiles" redirect="feature-profile" />
</template>
<script>
import Navbar from '@/components/Navbar'

export default {
  components: {
    Navbar,
  },
}
</script>
