<template>
  <div class="create-building">
    <ProfileAppbar />
    <div class="ma-4">
      <ProfileForm />
    </div>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Create Building
 * ==================================================================================
 **/

import { mapMutations } from 'vuex'
import ProfileAppbar from '@/views/Home/FeatureProfile/components/ProfileAppbar'
import ProfileForm from '@/views/Home/FeatureProfile/components/ProfileForm'

export default {
  components: {
    ProfileAppbar,
    ProfileForm,
  },

  created() {
    this.clearDetails()
  },

  methods: {
    ...mapMutations({
      clearDetails: 'profile/clearProfileDetails',
    }),
  },
}
</script>
