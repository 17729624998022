<template>
  <div class="newbooking_request">
    <div style="padding-bottom: 15px !important">
      <h4 class="section-title">New Booking Requests</h4>
    </div>
    <v-data-table
      :headers="headers"
      :items="pendingBookings"
      :server-items-length="totalBookingsPending"
      @click:row="editItem"
      :options.sync="options"
      :loading="loading"
      :dense="true"
      :footer-props="{
        'items-per-page-options': [5],
      }"
      @update:options="fetch"
      overflow-y-hidden
      :height="calculateTableHeight()"
    >
      <template v-slot:item.title="{ item }">
        <b-container fluid class="d-flex align-items-center">
          <div class="left-div">
            <user-avatar
              :avatar="getBookableImage(item)"
              size="30"
              class="mr-4"
            />
          </div>
          <div class="right-div ml-2">
            <div>{{ item.bookable.title }}</div>
          </div>
        </b-container>
      </template>
      <template v-slot:item.date="{ item }">
        <div class="first-line-text text-right">{{ item.date }}</div>
        <div class="second-line-text text-right">{{ item.time }}</div>
      </template>
    </v-data-table>
    <div class="div__button_see_all">
      <v-btn class="button_see_all" @click="seeAll" text>
        see all <v-icon>mdi-arrow-right</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import UserAvatar from '@/components/UserAvatar'
import ControlsMixin from '@/utils/mixins/Controls'
import FilterTableMixin from '@/utils/mixins/FilterTable'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import { get } from 'lodash'

export default {
  name: 'NewBookingRequest',

  mixins: [ControlsMixin, SnackbarMixin, ErrorHandlerMixin, FilterTableMixin],

  data() {
    return {
      loading: false,
      headers: [
        {
          text: 'Booking Request',
          value: 'title',
        },
        {
          text: 'Date/Time',
          align: 'right',
          value: 'date',
        },
      ],
      options: {},
    }
  },

  created() {
    this.fetch()
  },

  components: {
    UserAvatar,
  },

  computed: {
    ...mapState({
      pendingBookings: (state) => state.booking.pendingBookings,
      totalBookingsPending: (state) => state.booking.totalBookingsPending,
    }),
  },

  methods: {
    ...mapActions({
      getPendingBooking: 'booking/getPendingBooking',
    }),

    ...mapMutations({
      clearBookings: 'booking/clearBookingList',
    }),

    filterList(filterValue) {
      this.clearBookings()
      this.fetch(null, filterValue)
    },

    calculateTableHeight() {
      // Calculate the height based on the number of items per page (5 in this case)
      // You may need to adjust this calculation based on your specific styling and layout
      const itemHeight = 65 // Average height of a row, adjust as per your design
      const paginationHeight = 36 // Height of pagination controls
      const rowsPerPage = 5 // Number of rows per page
      return rowsPerPage * itemHeight + paginationHeight + 'px'
    },

    async fetch(options, page = 1) {
      if (this.loading) return

      let params = {
        page: options?.page || 1,
        sort: options?.sortBy[0]
          ? options.sortDesc[0]
            ? '-' + options.sortBy[0]
            : options.sortBy[0]
          : '',
        itemsPerPage: options?.itemsPerPage
          ? options?.itemsPerPage === -1
            ? this.totalUpcomingBookings
            : options.itemsPerPage
          : 5,
      }

      this.loading = true
      await this.getPendingBooking(params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },

    editItem(item) {
      this.$router.push({
        name: 'booking.details',
        params: { id: item.id },
      })
    },

    seeAll() {
      this.$router.push({
        name: 'bookings',
      })
    },

    getBookableImage(bookingItem) {
      return get(bookingItem, 'bookable.media.0', undefined)
    },
  },
}
</script>
<style lang="scss" scoped>
.newbooking_request .title {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 20px;
}
.div__button_see_all {
  text-align: right;
}
.button_see_all {
  text-transform: uppercase !important;
  color: #1976d2 !important;
  font-weight: 700;
  font-size: 12px;
  position: absolute;
  bottom: 0; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
  /* Other button styles */
}

.second-line-text {
  font-size: 0.8em;
}

.v-data-table {
  min-height: 100% !important;
}
</style>
