<template>
  <div class="lobby-tv-details py-8">
    <LobbyTvAppbar />

    <div class="ma-4">
      <ViewingTitle :title="displayName" />
      <DeleteOrRestore
        :model="lobbyTv"
        name="lobbyTv"
        redirect="/lobby-tvs"
        @deleted="onDelete"
      />

      <div class="d-flex mb-6">
        <v-tabs hide-slider class="building-info-tabs">
          <v-tab class="pa-0" v-for="(item, key) in tabItems" :key="key">
            {{ item.tab }}
          </v-tab>

          <v-tab-item class="col-sm-12 col-xs-12 mt-6 pa-0">
            <v-divider class="building-details__divider"></v-divider>

            <LobbyTvForm :lobbyTv="lobbyTv" />
          </v-tab-item>
        </v-tabs>
      </div>
    </div>

    <Loading :value="loading" />
  </div>
</template>

<script>
/**
 * ==================================================================================
 * View Building
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import ViewingTitle from '@/components/common/ViewingTitle'
import LobbyTvAppbar from '@/views/Home/Settings/LobbyTv/components/LobbyTvAppbar'
import LobbyTvForm from '@/views/Home/Settings/LobbyTv/components/LobbyTvForm'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Loading from '@/components/common/Loading'
import DeleteOrRestore from '@/components/fields/DeleteOrRestore'
import bus from '@/store/modules/bus'

export default {
  name: 'BuildingDetails',

  components: {
    ViewingTitle,
    LobbyTvAppbar,
    LobbyTvForm,
    Loading,
    DeleteOrRestore,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  data() {
    return {
      loading: false,
      tabItems: [{ tab: 'Info' }],
    }
  },

  computed: {
    ...mapState({
      lobbyTv: (state) => state.lobbyTv.lobbyTvDetails,
    }),

    displayName() {
      return this.lobbtyTv ? this.lobbtyTv.tv_name : null
    },
  },

  methods: {
    ...mapActions({
      getLobbyTvDetails: 'lobbyTv/getLobbyTvDetails',
    }),

    ...mapMutations({
      clearLobbyTvDetails: 'lobbyTv/clearLobbyTvDetails',
      removeBuilding: 'filter/removeBuilding',
    }),

    onDelete() {
      bus.$emit('buildingRemoved', this.building)
      this.removeBuilding(this.building)
    },

    async getLobbyTv() {
      this.loading = true
      await this.getLobbyTvDetails(this.$route.params.id)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },

  created() {
    this.getLobbyTv()
  },

  destroyed() {
    this.clearLobbyTvDetails()
  },

  watch: {
    $route() {
      this.getLobbyTv()
    },
  },
}
</script>

<style lang="scss" scoped>
.lobby-tv-details {
  &__divider {
    max-width: 400px;
  }

  @media (max-width: 768px) {
    &__divider {
      max-width: 100%;
    }
  }
}
</style>
