<template>
  <Navbar name="TVs" redirect="lobby-tvs" />
</template>
<script>
/**
 * ==================================================================================
 * Building Appbar
 * ==================================================================================
 **/

import Navbar from '@/components/Navbar'

export default {
  components: {
    Navbar,
  },
}
</script>
